import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Social, { SocialLink } from "../../ui/social";
import Text from "../../ui/text";
import Button from "../../ui/button";
import {
  SocialShareWrap,
  SocialMediaWrap,
  FloatingSocialBox,
} from "./social-share.style";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const SocialShare = ({
  socialStyle,
  socialButtonStyle,
  title,
  url,
  slug,
  id,
  image,
}) => {
  const [socialOpen, setSocialOpen] = useState(false);
  const getUrl = useStaticQuery(graphql`
    query SocialSiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const baseUrl = getUrl.site.siteMetadata.siteUrl;
  const socialHandler = () => {
    setSocialOpen((prev) => !prev);
  };

  return (
    <SocialShareWrap className="social-share">
      <Text as="span" className="share-label">
        Share this post
      </Text>
      <SocialMediaWrap>
        <Button {...socialButtonStyle} onClick={socialHandler}>
          <i className="far fa-share-alt"></i>
        </Button>
        <FloatingSocialBox isOpen={socialOpen}>
          <Social {...socialStyle} space={30}>
            <SocialLink
              path={`https://twitter.com/share?text=${title}&url=${baseUrl}/blog/${slug}`}
              label="Twitter"
            >
              <FaTwitter />
            </SocialLink>
            <SocialLink
              path={`https://www.facebook.com/sharer.php?u=${baseUrl}/blog/${slug}&title=${title}`}
              label="Facebook"
            >
              <FaFacebookF />{" "}
            </SocialLink>
            <SocialLink
              path={`https://www.linkedin.com/sharing/share-offsite/?url=${`${baseUrl}/blog/${slug}`}`}
              label="Linkedin"
            >
              <FaLinkedinIn />
            </SocialLink>
          </Social>
        </FloatingSocialBox>
      </SocialMediaWrap>
    </SocialShareWrap>
  );
};

SocialShare.propTypes = {
  socialButtonStyle: PropTypes.object,
  socialStyle: PropTypes.object,
};

SocialShare.defaultProps = {
  socialButtonStyle: {
    varient: "onlyIcon",
    width: "48px",
    shape: "bullet",
    hover: "false",
  },
  socialStyle: {
    fontSize: "12px",
    color: "#555",
  },
};

export default SocialShare;
